
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["onClose"],
  setup(_, context) {
    const onClose = () => {
      context.emit("onClose");
    };
    return {
      onClose,
    };
  },
});
