import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex",
    style: _normalizeStyle(`width:${_ctx.sliderWidth}px; height:28px`)
  }, [
    (_ctx.value !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("input", {
            class: "text-sm",
            type: "value",
            value: _ctx.value,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTextInput($event.target.value))),
            style: _normalizeStyle(`width:${_ctx.sliderWidth}px; height:16px`)
          }, null, 44, _hoisted_2),
          _createElementVNode("div", {
            class: "overflow-hidden bg-slate-400",
            style: _normalizeStyle(`width:${_ctx.sliderWidth}px; height:8px`),
            onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args)))
          }, [
            _createElementVNode("div", {
              class: "bg-blue-300",
              style: _normalizeStyle(`width:${_ctx.sliderWidth * _ctx.value}px; height:8px`)
            }, null, 4)
          ], 36)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}