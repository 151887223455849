
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["splitSegment"],
  setup(_, context) {
    const splitSegment = () => {
      context.emit("splitSegment");
    };
    return {
      splitSegment,
    };
  },
});
