import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2 rounded-xl border pl-2 pr-2 shadow-md"
}
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mb-40" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 2,
  class: "text-red-400"
}
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "text-red-400" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "mb-4" }
const _hoisted_17 = {
  class: "mb-1 block text-sm text-gray-700",
  for: "username"
}
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_21 = {
  key: 1,
  class: "ml-2"
}
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "mb-2" }
const _hoisted_25 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NetworkGate = _resolveComponent("NetworkGate")!

  return (_ctx.selection && !_ctx.selection.asset.registered)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("b", null, [
            (!_ctx.drawing)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.selection.asset.name) + ", ", 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.selection.asset.category), 1)
          ])
        ]),
        (_ctx.selection.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.selection.asset.image,
                class: "m-2 inline-block w-16 rounded-xl"
              }, null, 8, _hoisted_5),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("mintPanel.preparing")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selection.images, (image) => {
                return (_openBlock(), _createElementBlock("img", {
                  key: image,
                  src: image,
                  class: "m-2 inline-block w-16 rounded-xl"
                }, null, 8, _hoisted_8))
              }), 128)),
              (_ctx.messageRef)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_ctx.messageRef == 'message.minting')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.processing")), 1)
                        ]))
                      : (_ctx.messageRef == 'message.minted')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("mintPanel.thanks")), 1)
                          ]))
                        : (_ctx.messageRef == 'message.not_available')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t("mintPanel.sorry")), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t("mintPanel.error1")), 1),
                              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.messageRef), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t("mintPanel.error2")), 1)
                            ]))
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_15, [
                    _createVNode(_component_NetworkGate, {
                      expectedNetwork: _ctx.addresses.chainId
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("label", _hoisted_17, [
                            _createTextVNode(_toDisplayString(_ctx.$t("mintPanel.writeName")) + " ", 1),
                            _createElementVNode("span", {
                              class: _normalizeClass(_ctx.validName ? '' : 'font-bold text-red-600')
                            }, _toDisplayString(_ctx.$t("mintPanel.maxLength")), 3)
                          ]),
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.minterName) = $event)),
                            class: "focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none",
                            id: "username",
                            type: "text",
                            placeholder: _ctx.$t('mintPanel.placeHolder')
                          }, null, 8, _hoisted_18), [
                            [
                              _vModelText,
                              _ctx.minterName,
                              void 0,
                              { trim: true }
                            ]
                          ]),
                          (_ctx.validName)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                                _createElementVNode("button", {
                                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mint && _ctx.mint(...args))),
                                  class: "mt-2 inline-block rounded bg-green-600 px-6 py-2.5 leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg"
                                }, _toDisplayString(_ctx.$t("mintPanel.mint")), 1),
                                (_ctx.hasAnyRemix)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.mintPrice) + "ETH", 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$t("mintPanel.free")), 1))
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_22, [
                                _createElementVNode("button", {
                                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mint && _ctx.mint(...args))),
                                  disabled: "",
                                  class: "mt-2 inline-block rounded bg-gray-400 px-6 py-2.5 leading-tight text-gray-200 shadow-md"
                                }, _toDisplayString(_ctx.$t("mintPanel.mint")), 1)
                              ]))
                        ]),
                        _createElementVNode("div", null, [
                          _renderSlot(_ctx.$slots, "default"),
                          (_ctx.priceRange.high > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$tc("mintPanel.mintMessage1", {
                    low: _ctx.priceRange.low,
                    high: _ctx.priceRange.high,
                  })), 1),
                                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$tc("mintPanel.mintMessage2", {
                    tokensPerAsset: _ctx.tokensPerAsset - 1,
                    bonousTokensPerAsset: _ctx.tokensPerAsset - 2,
                  })), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 3
                    }, 8, ["expectedNetwork"])
                  ]))
            ]))
      ]))
    : _createCommentVNode("", true)
}