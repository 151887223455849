import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w=32 -z-10 overflow-hidden" }
const _hoisted_2 = { class: "absolute -z-10 w-32 overflow-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.drawing.remix.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "w-32",
            src: _ctx.drawing.remix.image,
            style: _normalizeStyle(`Transform:${_ctx.transform}`)
          }, null, 12, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("img", {
      src: _ctx.svgImageFromDrawing(_ctx.drawing),
      class: "absolute -z-10 w-32"
    }, null, 8, _hoisted_4),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drawing.overlays, (overlay, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        style: {"width":"128","height":"128"},
        class: "absolute overflow-hidden"
      }, [
        _createElementVNode("img", {
          class: "-z-10 w-32",
          style: _normalizeStyle(`Transform: ${_ctx.overlayTransform(overlay)}`),
          src: overlay.image
        }, null, 12, _hoisted_5)
      ]))
    }), 128)),
    _createElementVNode("img", {
      src: _ctx.svgImageFromDrawing(_ctx.drawing),
      class: "w-32",
      style: {"visibility":"hidden"}
    }, null, 8, _hoisted_6)
  ]))
}