
import { defineComponent, PropType, computed } from "vue";
import { useCanvasParams, Pos } from "@/utils/canvasUtil";
import {
  Layer,
  LayerType,
  Drawing,
  Remix,
  Overlay,
  transformStyle,
} from "@/models/point";
import TokenPicker from "@/components/Canvas/TokenPicker.vue";
import AssetPicker from "@/components/Canvas/AssetPicker.vue";

export default defineComponent({
  components: {
    TokenPicker,
    AssetPicker,
  },
  props: {
    drawing: {
      type: Object as PropType<Drawing>,
      required: true,
    },
    layerIndex: {
      type: Number,
      required: true,
    },
    overlayIndex: {
      type: Number,
      required: true,
    },
    newLayer: {
      type: Object as PropType<Layer>,
      required: true,
    },
    remixes: {
      type: Array as PropType<Remix[]>,
      required: true,
    },
    currentLayerType: {
      type: Number as PropType<LayerType>,
      require: true,
    },
    addresses: {
      type: Object,
      required: true,
    },
    canvasOffset: {
      type: Object as PropType<Pos>,
      required: true,
    },
  },
  emits: [
    "onSelectLayer",
    "updateLayers",
    "updateRemix",
    "remixSelected",
    "updateOverlays",
    "onSelectOverlay",
  ],
  setup(props, context) {
    const { canvasParams } = useCanvasParams();
    const isLayerType = computed(() => {
      return props.currentLayerType == LayerType.LAYER;
    });
    const isRemixType = computed(() => {
      return props.currentLayerType == LayerType.REMIX;
    });
    const isOverlayType = computed(() => {
      return props.currentLayerType == LayerType.OVERLAY;
    });
    const onSelectLayer = (index: number) => {
      context.emit("onSelectLayer", index);
    };
    const insertLayer = (index: number) => {
      const array = props.drawing.layers.map((layer) => layer);
      array.splice(index, 0, props.newLayer);
      context.emit("updateLayers", array, props.layerIndex + 1);
    };
    const swapLayer = (index: number) => {
      const array = props.drawing.layers.map((layer) => layer);
      const tmp = array[index];
      array[index] = array[index - 1];
      array[index - 1] = tmp;
      context.emit("updateLayers", array, props.layerIndex);
    };
    const copyLayer = (index: number) => {
      const array = props.drawing.layers.map((layer) => layer);
      const layer = { ...props.drawing.layers[index] };
      array.splice(index, 0, layer);
      context.emit("updateLayers", array, props.layerIndex + 1);
    };
    const deleteLayer = () => {
      if (props.drawing.layers.length == 1) {
        return;
      }
      const array = props.drawing.layers.filter((layer, index: number) => {
        return index != props.layerIndex;
      });
      context.emit("updateLayers", array, props.layerIndex - 1);
    };

    const onSelectOverlay = (index: number) => {
      context.emit("onSelectOverlay", index);
    };
    const swapOverlay = (index: number) => {
      const array = props.drawing.overlays.map((layer) => layer);
      const tmp = array[index];
      array[index] = array[index - 1];
      array[index - 1] = tmp;
      context.emit("updateOverlays", array, props.overlayIndex);
    };
    const copyOverlay = (index: number) => {
      const array = props.drawing.overlays.map((layer) => layer);
      const overlay = { ...props.drawing.overlays[index] };
      array.splice(index, 0, overlay);
      context.emit("updateOverlays", array, props.overlayIndex + 1);
    };
    const deleteOverlay = () => {
      const array = props.drawing.overlays.filter((overlay, index: number) => {
        return index != props.overlayIndex;
      });
      context.emit("updateOverlays", array, props.overlayIndex - 1);
    };

    const updateRemix = (remix: Remix | null) => {
      context.emit("updateRemix", remix);
    };
    const remixSelected = () => {
      context.emit("remixSelected");
    };
    const AssetSelected = (overlay: Overlay) => {
      const overlays = props.drawing.overlays.map((overlay) => overlay);
      overlays.push(overlay);
      context.emit("updateOverlays", overlays);
      onSelectOverlay(overlays.length - 1); // select the new one
    };
    const overlayTransform = (index: number) => {
      return transformStyle(
        props.drawing.overlays[index].transform,
        canvasParams.value.sidew / canvasParams.value.assw
      );
    };

    return {
      canvasParams,
      onSelectLayer,
      insertLayer,
      swapLayer,
      copyLayer,
      deleteLayer,
      onSelectOverlay,
      swapOverlay,
      copyOverlay,
      deleteOverlay,
      updateRemix,
      remixSelected,
      isLayerType,
      isRemixType,
      AssetSelected,
      isOverlayType,
      overlayTransform,
    };
  },
});
