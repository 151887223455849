
import { defineComponent } from "vue";
export default defineComponent({
  props: ["grid"],
  emits: ["toggleGrid"],
  setup(_, context) {
    const toggleGrid = () => {
      context.emit("toggleGrid");
    };
    return {
      toggleGrid,
    };
  },
});
