
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["togglePoint"],
  props: ["isSharpCorner"],
  setup(_, context) {
    const togglePoint = () => {
      context.emit("togglePoint");
    };
    return {
      togglePoint,
    };
  },
});
