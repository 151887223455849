
import { defineComponent } from "vue";
export default defineComponent({
  props: ["isUndoable", "isRedoable"],
  emits: ["undo", "redo"],
  setup(_, context) {
    const undo = () => {
      context.emit("undo");
    };
    const redo = () => {
      context.emit("redo");
    };
    return {
      undo,
      redo,
    };
  },
});
