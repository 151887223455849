
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: ["value"],
  emits: ["updateValue"],
  setup(props, context) {
    const sliderWidth = 80;
    const mousedown = (evt: MouseEvent) => {
      context.emit("updateValue", evt.offsetX / sliderWidth);
      evt.preventDefault();
    };
    const onTextInput = (textValue: string) => {
      const newValue = parseFloat(textValue);
      if (!isNaN(newValue)) {
        context.emit("updateValue", newValue);
      }
    };
    return {
      sliderWidth,
      mousedown,
      onTextInput,
    };
  },
});
