import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-4 mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_4 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.tokens.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isRecent)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("message.recentNFTs", { count: _ctx.tokens.length })), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("message.nftListTitle", { count: _ctx.tokens.length })), 1))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokens, (token) => {
            return (_openBlock(), _createElementBlock("span", {
              key: token.tokenId
            }, [
              _createElementVNode("a", {
                href: `${_ctx.OpenSeaPath}/${token.tokenId}`,
                class: "cursor-pointer",
                target: "_blank"
              }, [
                _createElementVNode("img", {
                  src: token.image,
                  class: "mr-1 mb-1 inline-block w-14 rounded-xl"
                }, null, 8, _hoisted_6)
              ], 8, _hoisted_5)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}