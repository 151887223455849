
import { defineComponent, ref, computed } from "vue";
import { ethers } from "ethers";
import { useRoute } from "vue-router";
import { displayAddress } from "@/utils/MetaMask";
import Canvas from "@/components/Canvas/Canvas.vue";
import {
  Drawing,
  identityTransform,
  Layer,
  svgImageFromPath,
  pathFromPoints,
  transformString,
  Remix,
} from "@/models/point";
import DrawingItem from "@/components/Canvas/DrawingItem.vue";
import MintPanel from "@/components/MintPanel.vue";
import { getContractAddresses } from "@/utils/networks";
import { useOnSelect } from "@/utils/mintUtils";
import { loadAssets } from "../utils/createAsset";
import { Token } from "@/models/token";
import { fetchTokensRemix } from "@/utils/fetchTokens";
import { getAddresses } from "@/utils/const";
import References from "@/components/References.vue";
import NFTList from "@/components/NFTList.vue";
import { OriginalAssetData, OriginalAssetDataSet } from "@/models/asset";
import { roundRect } from "@/utils/canvasUtil";
import { weiToEther } from "@/utils/currency";
// import { v4 as uuidv4 } from "uuid";

const AssetStore = {
  wabi: require("../abis/AssetStore.json"), // wrapped abi
};

const contentsToken = {
  wabi: require("../abis/DrawYourOwn.json"), // wrapped abi
};

const AssetComposer = {
  wabi: require("@/abis/AssetComposer.json"), // wrapped abi
};

const AssetProviderRegistry = {
  wabi: require("@/abis/AssetProviderRegistry.json"), // wrapped abi
};

const AssetStoreProvider = {
  wabi: require("@/abis/AssetStoreProvider.json"), // wrapped abi
};

interface Info {
  nextIndex: number;
  keys: string[];
}

const baseInfo: Info = {
  nextIndex: 0,
  keys: [],
};

const keyInfo = "manifest";
const keyDrawing = "drawing";
const priceRange = { low: 0, high: 0 };

export default defineComponent({
  components: {
    Canvas,
    MintPanel,
    References,
    NFTList,
    DrawingItem,
  },
  setup() {
    const route = useRoute();
    const network =
      typeof route.query.network == "string" ? route.query.network : "goerli";
    const addresses = getContractAddresses(network)!;
    addresses.tokenAddress = addresses.drawAddress;

    const { EtherscanStore, EtherscanToken, OpenSeaPath } = getAddresses(
      addresses.network,
      addresses.storeAddress,
      addresses.tokenAddress
    );

    const provider =
      addresses.network == "localhost"
        ? new ethers.providers.JsonRpcProvider()
        : new ethers.providers.InfuraProvider(addresses.network);

    const assetStoreRO = new ethers.Contract(
      addresses.storeAddress,
      AssetStore.wabi.abi,
      provider
    );
    const tokenRO = new ethers.Contract(
      addresses.tokenAddress,
      contentsToken.wabi.abi,
      provider
    );
    const assetComposer = new ethers.Contract(
      addresses.composerAddress,
      AssetComposer.wabi.abi,
      provider
    );
    const assetProviderRegistry = new ethers.Contract(
      addresses.registryAddress,
      AssetProviderRegistry.wabi.abi,
      provider
    );

    const mintPrice = ref<number>(0.01); // to be over-written
    const fetchMintPrice = async () => {
      const result = await tokenRO.functions.mintPrice();
      mintPrice.value = weiToEther(result[0]);
    };
    fetchMintPrice();

    const remixes = ref<Remix[]>([]);
    const tokens = ref<Token[]>([]);
    const { onSelect, selection, tokensPerAsset } = useOnSelect(4, tokenRO);

    provider.once("block", async () => {
      tokenRO.on(tokenRO.filters.Transfer(), async (from, to, tokenId) => {
        if (
          tokenId.toNumber() % tokensPerAsset.value == 0 &&
          tokenId.toNumber() >= tokens.value.length * tokensPerAsset.value
        ) {
          console.log("*** e.Transfer calling fetchToken");
          fetchPrimaryTokens();
        }
      });
      // event Payout(string providerKey, uint256 assetId, address payable to, uint256 amount);
      const [providerId] = await assetProviderRegistry.functions.getProviderId(
        "asset"
      );
      //console.log("providerId", providerId.toNumber());
      const [assetInfo] = await assetProviderRegistry.functions.getProvider(
        providerId
      );
      console.log(
        "assetInfo",
        assetInfo.key,
        assetInfo.name,
        displayAddress(assetInfo.provider)
      );
      const assetStoreProvider = new ethers.Contract(
        assetInfo.provider,
        AssetStoreProvider.wabi.abi,
        provider
      );

      assetStoreProvider.on(
        assetStoreProvider.filters.Payout(),
        async (providerKey, assetId, to, amount) => {
          console.log(
            "*** e.Payout",
            providerKey,
            assetId.toNumber(),
            displayAddress(to),
            weiToEther(amount)
          );
        }
      );
    });

    const fetchPrimaryTokens = async () => {
      if (tokensPerAsset.value == 0) {
        const result = await tokenRO.functions.tokensPerAsset();
        tokensPerAsset.value = result[0].toNumber();
      }

      const resultSupply = await tokenRO.functions.totalSupply();
      const count = resultSupply[0].toNumber() / tokensPerAsset.value;
      console.log("totalSupply", count, count * tokensPerAsset.value);

      fetchTokensRemix(
        count,
        remixes.value,
        tokensPerAsset.value,
        0,
        tokenRO,
        (updateTokens) => {
          remixes.value = updateTokens;
          tokens.value = updateTokens.map((remix) => {
            return { tokenId: remix.tokenId!, image: remix.image! };
          });
        }
      );
    };
    fetchPrimaryTokens();

    const drawings = ref<Drawing[]>([]);
    const resultInfo = localStorage.getItem(keyInfo);
    const info = ref<Info>(
      resultInfo ? JSON.parse(resultInfo) || baseInfo : baseInfo
    );

    //console.log("** info", info.value.nextIndex, info.value.keys);

    drawings.value = info.value.keys.map((key, index) => {
      const result = localStorage.getItem(key);
      //console.log("result", key, index, result);
      const drawing: Drawing = result
        ? JSON.parse(result) || { layers: [] }
        : { layers: [] };

      // Backward compativility
      drawing.overlays = drawing.overlays || [];
      drawing.remix = drawing.remix || { transform: identityTransform };
      drawing.layers = drawing.layers.map((layer) => {
        layer.points = layer.points.map((point) => {
          point.r = point.r || 0.553;
          return point;
        });
        return layer;
      });
      drawing.stroke = drawing.stroke || 0;

      // console.log("** setup:overlays.length", index, drawing.layers.length, drawing.overlays.length);
      return drawing;
    });

    // Delete gabages in the localStorage
    new Array(info.value.nextIndex).fill(0).forEach((v, index) => {
      const key = `${keyDrawing}${index}`;
      const iFound = info.value.keys.indexOf(key);
      if (iFound == -1) {
        const result = localStorage.getItem(key);
        if (result) {
          console.log("removing deleted item", key, result.length);
          localStorage.removeItem(key);
        }
      }
    });

    const showCanvas = ref<boolean>(false);
    const selectedIndex = ref<number>(9999);
    const selectedDrawing = computed(() => {
      return drawings.value[selectedIndex.value];
    });
    const onDrawingSelect = async (index: number) => {
      selectedIndex.value = index;
      const drawing = selectedDrawing.value;
      const asset: OriginalAssetData = {
        name: "", // contract generate it,
        parts: drawing.layers.map((layer) => {
          return { body: layer.path, color: layer.color };
        }),
      };
      const d = new Date();
      const dataset: OriginalAssetDataSet = {
        group: `${d.getMonth() + 1}-${d.getFullYear()}`,
        category: `${d.getDate()} (CC Share Earnings)`,
        width: 1024,
        height: 1024,
        assets: [asset],
      };
      const loadedAssets = loadAssets(dataset);
      let tag = "item";

      const remix = {
        def: "",
        use: "",
      };
      if (drawing.remix.image) {
        const result = await tokenRO.functions.generateSVGPart(
          drawing.remix.tokenId
        );
        remix.def = result[0];
        remix.use = ` <use href="#${result[1]}" transform="${
          remixTransformString.value
        }" fill="${drawing.remix.color || ""}" />\n`;
      }
      loadedAssets[0].svgPart =
        loadedAssets[0].svgPart +
        remix.def +
        "\n" +
        drawing.overlays.map((overlay) => overlay.svgPart).join("") +
        `<g id="mixed">\n` +
        remix.use +
        ` <use href="#item" />\n` +
        drawing.overlays
          .map(
            (overlay) =>
              ` <use href="#${overlay.svgTag}" 
                     transform="${transformString(overlay.transform)}"
                     fill="${overlay.fill}" />\n`
          )
          .join("") +
        `</g>\n`;
      tag = "mixed";
      onSelect(loadedAssets[0], tag);
    };
    const onOpen = () => {
      showCanvas.value = true;
    };
    const onDelete = () => {
      info.value = {
        keys: info.value.keys.filter((_, index) => {
          return index !== selectedIndex.value;
        }),
        nextIndex: info.value.nextIndex, // Notice that we don't decrement!
      };
      drawings.value = drawings.value.filter((_, index) => {
        return index !== selectedIndex.value;
      });
      if (info.value.keys.length === selectedIndex.value) {
        selectedIndex.value = selectedIndex.value - 1;
      }
      onDrawingSelect(selectedIndex.value);
      localStorage.setItem(keyInfo, JSON.stringify(info.value));
    };
    const onCreate = () => {
      const keys = info.value.keys;
      // Prepare to open
      selectedIndex.value = keys.length;
      const path = pathFromPoints(roundRect);
      const layer: Layer = {
        points: roundRect,
        color: "",
        path,
        svgImage: svgImageFromPath(path, ""),
      };
      const drawing: Drawing = {
        layers: [layer],
        overlays: [],
        remix: { tokenId: 0, transform: identityTransform },
        stroke: 0,
      };

      const newDrawings: Drawing[] = drawings.value.map((body) => body);
      newDrawings.push(drawing);
      drawings.value = newDrawings;

      // Update the info and save it
      const key = `${keyDrawing}${info.value.nextIndex}`;
      keys.push(key);
      localStorage.setItem(key, JSON.stringify(drawing));
      info.value = {
        nextIndex: info.value.nextIndex + 1,
        keys,
      };
      localStorage.setItem(keyInfo, JSON.stringify(info.value));
      showCanvas.value = true;
    };
    const minted = () => {
      selection.value = null;
      selectedIndex.value = 9999;
    };
    const onClose = (output: Drawing) => {
      //console.log("** onClose:overlays.length", output.overlays.length);
      drawings.value = drawings.value.map((drawing, index) => {
        if (index == selectedIndex.value) {
          return output;
        }
        return drawing;
      });
      localStorage.setItem(
        info.value.keys[selectedIndex.value],
        JSON.stringify(output)
      );
      showCanvas.value = false;
      selection.value = null; // force redraw
      onDrawingSelect(selectedIndex.value);
    };
    const remixTransformString = computed(() => {
      const xf = selectedDrawing.value.remix.transform;
      return transformString(xf);
    });
    return {
      showCanvas,
      onOpen,
      onDelete,
      onCreate,
      onClose,
      onDrawingSelect,
      drawings,
      selectedDrawing,
      selectedIndex,
      priceRange,
      tokensPerAsset,
      assetStoreRO,
      tokenAbi: contentsToken.wabi.abi,
      tokenName: "OnChainCanvas",
      selection,
      addresses,
      tokens,
      remixes,
      EtherscanStore,
      EtherscanToken,
      OpenSeaPath,
      remixTransformString,
      minted,
      mintPrice,
    };
  },
});
