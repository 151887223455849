
import { defineComponent } from "vue";
export default defineComponent({
  props: ["cursors"],
  emits: ["deletePoint"],
  setup(_, context) {
    const deletePoint = () => {
      context.emit("deletePoint");
    };
    return {
      deletePoint,
    };
  },
});
