import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "material-icons"
}
const _hoisted_2 = {
  key: 1,
  class: "material-icons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePoint && _ctx.togglePoint(...args)))
  }, [
    (_ctx.isSharpCorner)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "check_box_outline_blank"))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "radio_button_unchecked"))
  ]))
}