
import { defineComponent, ref, computed } from "vue";
import { Remix, transformStyle } from "@/models/point";

export default defineComponent({
  props: ["remixes", "remix", "canvasParams", "isRemixType", "canvasOffset"],
  emits: ["updateRemix", "remixSelected"],
  setup(props, context) {
    const transform = computed(() => {
      return transformStyle(
        props.remix.transform,
        props.canvasParams.sidew / props.canvasParams.assw
      );
    });
    const showPopup = ref<boolean>(false);
    const onOpen = () => {
      showPopup.value = !showPopup.value;
    };
    const onSelect = (newRemix: Remix | null) => {
      context.emit("updateRemix", newRemix);
      showPopup.value = false;
    };
    const onSelectRemix = () => {
      context.emit("remixSelected");
    };
    return {
      onOpen,
      showPopup,
      onSelect,
      transform,
      onSelectRemix,
    };
  },
});
